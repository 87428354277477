import React, { useContext, useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon, GlobeInternetIcon } from '../atoms/Icons';
import { MessageContext } from 'contexts/GlobalStateMessage';

const CardSetLanguage = () => {
  const { language, setLanguage } = useContext(MessageContext);
  const [openPopLanguage, setOpenPopLanguage] = useState(false);

  const handleOpenPopLanguage = () => {
    setOpenPopLanguage(!openPopLanguage);
  };

  const handleSetLanguage = (lang) => {
    localStorage.setItem('lang', lang);
    setLanguage(lang === 'Indonesia' ? 'Bahasa Indonesia' : lang);
  };

  return (
    <>
      <span>
        <GlobeInternetIcon color="#000000" />
      </span>
      <span className="ml-1 mr-2">{language}</span>
      <div onClick={handleOpenPopLanguage} className="cursor-pointer">
        {openPopLanguage ? (
          <ArrowUpIcon width="24" height="28" />
        ) : (
          <ArrowDownIcon />
        )}
      </div>

      {openPopLanguage && (
        <div className="relative inline-block">
          <div className="absolute mt-2 w-full">
            <div className="w-48 absolute z-50 mt-4 bg-white p-4 rounded shadow-lg -left-52">
              <div className="w-full flex flex-wrap gap-y-3 space-y-1">
                <div
                  onClick={() => {
                    handleSetLanguage('English');
                    handleOpenPopLanguage();
                  }}
                  className="w-full inline-flex gap-2 items-center cursor-pointer mt-2"
                >
                  <span className="font-normal text-sm">English</span>
                </div>

                <div
                  onClick={() => {
                    handleSetLanguage('Indonesia');
                    handleOpenPopLanguage();
                  }}
                  className="w-full inline-flex gap-2 items-center cursor-pointer mt-2"
                >
                  <span className="font-normal text-sm">Bahasa Indonesia</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CardSetLanguage;
