import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navbar } from './sidebar.elements';
import { useLocation, useNavigate } from 'react-router-dom';
import Image from 'components/UI/atoms/Image';
import LogoConfluidity from 'components/assets/images/Logo_Confluidty.png';
import IconConfluidity from 'components/assets/images/Icon_Confluidity.png';
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ChatbotActiveIcon,
  ChatbotIcon,
  FolderMenuActiveIcon,
  FolderMenuIcon,
  LogActiveIcon,
  LogIcon,
  ManageOrgActiveIcon,
  ManageOrgIcon,
  PlusCircleIcon,
  OrganizationInfoActiveIcon,
  OrganizationInfoIcon,
  UsersMenuActiveIcon,
  UsersMenuIcon,
  ApiMenuIcon,
  ApiMenuIconActive,
  FolderIcon,
  KeyIcon,
  PadLockIcon,
  LogOutIcon,
} from 'components/UI/atoms/Icons';
import { checkRoleAuth, clearLocalStorageData } from 'utils/authUtils';
import CardItemHistoryChat from 'components/UI/molecules/CardItemHistoryChat';
import { MessageContext } from 'contexts/GlobalStateMessage';
import { useDetectOutsideClick } from 'helper/useDetectOutsideClick';
import {
  GlobalStateContext,
  GlobalStateUpdaterContext,
} from 'contexts/GlobalStateContext';
import NavbarMobile from '../Navbar/NavbarMobile';
import DefaultUser from 'components/assets/images/default-picture.png';

const role = checkRoleAuth();
const menuItems = [
  // {
  //   label: 'Chatbot',
  //   path: '/chatbot',
  //   icon: { default: <ChatbotIcon />, active: <ChatbotActiveIcon /> },
  //   children: [
  //     { label: 'Upload Document Rahasia', path: '/chatbot/submenu1' },
  //     { label: 'Edit Document Tag', path: '/chatbot/submenu2' },
  //   ],
  // },
  // {
  //   label: 'Company Structure',
  //   path: '/company',
  //   icon: { default: <GitMergeIcon />, active: <GitMergeActiveIcon /> },
  // },
  // {
  //   label: 'Document',
  //   path: '/document',
  //   icon: { default: <DocumentIcon />, active: <DocumentActiveIcon /> },
  // },
  ...(role === 'SUPER_ADMIN'
    ? [
        {
          label: 'Organization Management',
          path: '/organization-management',
          icon: { default: <ManageOrgIcon />, active: <ManageOrgActiveIcon /> },
        },
        {
          label: 'History',
          path: '/history-log',
          icon: { default: <LogIcon />, active: <LogActiveIcon /> },
        },
      ]
    : []),
  ...(role === 'ADMIN'
    ? [
        {
          label: 'Organization Info',
          path: '/organization-info',
          icon: {
            default: <OrganizationInfoIcon />,
            active: <OrganizationInfoActiveIcon />,
          },
        },
        {
          label: 'Users',
          path: '/users',
          icon: {
            default: <UsersMenuIcon />,
            active: <UsersMenuActiveIcon />,
          },
        },
        {
          label: 'Document',
          path: '/document-admin',
          icon: {
            default: <FolderMenuIcon />,
            active: <FolderMenuActiveIcon />,
          },
        },
        {
          label: 'History',
          path: '/history-log',
          icon: { default: <LogIcon />, active: <LogActiveIcon /> },
        },
        {
          label: 'API List',
          path: '/api-list',
          icon: { default: <ApiMenuIcon />, active: <ApiMenuIconActive /> },
        },
      ]
    : []),
  ...(role === 'USER'
    ? [
        {
          label: 'Document',
          path: '/document',
          icon: {
            default: <FolderMenuIcon />,
            active: <FolderMenuActiveIcon />,
          },
        },
      ]
    : []),
];

const Sidebar = () => {
  const nameUser = localStorage.getItem('name');

  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState({});
  const [showDropDown, setShowDropDown] = useState(false);
  const [showDelayedContent, setShowDelayedContent] = useState(false);

  const responsiveSide = useContext(GlobalStateContext);
  const setResponsiveSide = useContext(GlobalStateUpdaterContext);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, true);
  const onClickMenu = () => {
    setIsActive(!isActive);
  };
  const { setMessage, setFormData } = useContext(MessageContext);

  useEffect(() => {
    let timer;
    if (!responsiveSide) {
      timer = setTimeout(() => {
        setShowDelayedContent(true);
      }, 300); // Set your delay here in milliseconds
    } else {
      setShowDelayedContent(false);
    }
    return () => clearTimeout(timer);
  }, [responsiveSide]);

  const toggleExpand = (label) => {
    setExpanded((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  const togleResponsiveSide = () => {
    setResponsiveSide(!responsiveSide);
  };

  const handleNewChat = () => {
    setFormData({
      chat_id: '',
      files: [],
      message: '',
    });
    setMessage([]);
  };

  const handleNewChatDetail = () => {
    setFormData({
      chat_id: '',
      files: [],
      message: '',
    });
    setMessage([]);
    navigate('/chatbot');
  };

  const handleLogout = () => {
    clearLocalStorageData();
    window.location.href = '/login';
  };

  const handleChangePassword = () => {
    window.location.href = '/change-password';
  };

  return (
    <React.Fragment>
      <NavbarMobile onClick={onClickMenu} ref={dropdownRef} />
      <Navbar
        className={`${isActive ? 'active' : 'inactive'} fixed top-0 left-0 z-50 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 aria-label="Sidebar" ${responsiveSide && 'small-nav'}`}
        id="default-sidebar"
      >
        <div className="w-full mt-4 py-5 flex items-center relative">
          <div
            className={`flex flex-grow ${responsiveSide ? 'justify-center' : 'justify-between pl-5'} w-10 align-middle`}
          >
            <Image
              src={responsiveSide ? IconConfluidity : LogoConfluidity}
              alt="logo confluidity"
              className="cursor-pointer"
            />
            {!isActive && !responsiveSide && (
              <span className="text-xs text-slate-500 mr-auto ml-2">
                {role === 'SUPER_ADMIN' && 'Admin Panel'}
              </span>
            )}
          </div>
          {isActive ? null : (
            <div
              onClick={togleResponsiveSide}
              className="w-7 h-7 bg-white flex items-center justify-center border-2 rounded-full absolute right-0 transform translate-x-1/2 cursor-pointer"
            >
              {responsiveSide ? <ArrowRightIcon /> : <ArrowLeftIcon />}
            </div>
          )}
        </div>
        {/* di figma super admin gada fitur chat? */}
        {role !== 'SUPER_ADMIN' && (
          <div className="w-full transition-all duration-500 flex justify-center py-3 px-5">
            {responsiveSide ? (
              <div
                className="cursor-pointer"
                onClick={() => navigate('/chatbot')}
              >
                {location.pathname.startsWith('/chatbot') ? (
                  <ChatbotActiveIcon />
                ) : (
                  <ChatbotIcon />
                )}
              </div>
            ) : (
              <button
                onClick={
                  location.pathname === '/chatbot'
                    ? handleNewChat
                    : handleNewChatDetail
                }
                className={`flex w-full justify-center items-center gap-2 rounded-lg my-2 px-3 py-2 text-base font-bold leading-6 text-purple-600 shadow-sm hover:bg-purple-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600 bg-purple-100 transition-all duration-500`}
              >
                <PlusCircleIcon color="#5D42BD" width="24" height="24" /> New
                Chat
              </button>
            )}
          </div>
        )}
        {menuItems?.map((item) => (
          <div key={item.path} className="w-full transition-all duration-500">
            {responsiveSide && !showDelayedContent && (
              <div
                onClick={() => navigate(item.path)}
                className={`w-full justify-center inline-flex items-center gap-2 cursor-pointer ${
                  location.pathname.startsWith(item.path)
                    ? 'text-blue-500'
                    : 'text-slate-500'
                }`}
              >
                {location.pathname.startsWith(item.path)
                  ? item.icon.active
                  : item.icon.default}
              </div>
            )}

            {!responsiveSide && showDelayedContent && (
              <div
                onClick={() => navigate(item.path)}
                className={`w-full ${responsiveSide ? 'justify-center' : 'px-3'} inline-flex items-center gap-2 cursor-pointer ${
                  location.pathname.startsWith(item.path)
                    ? 'text-blue-500'
                    : 'text-slate-500'
                }`}
                style={{
                  color: location.pathname.startsWith(item.path)
                    ? '#6D51D7'
                    : '#64748b',
                }}
              >
                <div
                  className={`w-full flex flex-wrap  px-3 py-3 rounded-lg items-center gap-2 ${
                    location.pathname.startsWith(item.path) && 'bg-slate-100'
                  }`}
                >
                  {location.pathname.startsWith(item.path)
                    ? item.icon.active
                    : item.icon.default}

                  {!responsiveSide && showDelayedContent && (
                    <div className="flex items-center">
                      <span
                        className={`font-medium ${role === 'SUPER_ADMIN' ? 'text-xs' : 'text-base'}`}
                      >
                        {item.label}
                      </span>
                      {item.label === 'Chatbot' && (
                        <div
                          className="ml-16"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleExpand(item.label);
                          }}
                        >
                          {expanded[item.label] ? (
                            <ArrowUpIcon />
                          ) : (
                            <ArrowDownIcon />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}

        {role === 'ADMIN' && (
          <div className="px-4 mt-2">
            <div className="border-t-2"></div>
          </div>
        )}
        {!responsiveSide && role !== 'SUPER_ADMIN' && <CardItemHistoryChat />}

        <div className="px-4 mt-auto md:hidden">
          <div className="border-t border-gray-100 px-3">
            <div
              className="flex items-center gap-2 py-3"
              onClick={() => setShowDropDown(!showDropDown)}
            >
              <div className="bg-slate-500 h-8 w-8 rounded-full flex items-center justify-center">
                <Image
                  src={DefaultUser}
                  alt="logo confluidity"
                  className="w-5 h-5"
                />
              </div>
              <div className="text-sm font-semibold">{nameUser}</div>
              <div className="ml-auto">
                <ArrowDownIcon />
              </div>
            </div>
            <div className={`relative ${showDropDown ? '' : 'h-0 hidden'} `}>
              <div className="flex gap-3 py-3">
                <FolderIcon width="18" height="18" />{' '}
                <span className="font-medium text-sm">Memory Log</span>
              </div>
              <div className="flex gap-3 py-3">
                <KeyIcon />{' '}
                <span className="font-medium text-sm">Setup API Keys</span>
              </div>
              <div className="flex gap-3 py-3" onClick={handleChangePassword}>
                <PadLockIcon width="22" height="22" />{' '}
                <span className="font-medium text-sm">Edit Password</span>
              </div>
            </div>
            <div className="flex gap-3 py-3" onClick={handleLogout}>
              <LogOutIcon /> <span className="font-medium text-sm">Logout</span>
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default Sidebar;
