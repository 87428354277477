import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import sentryJSON from './sentry-release';

import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { Config } from 'utils/initialConfig';

// Sentry.init({
//   dsn: Config?.REACT_APP_SENTRY_DSN,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: sampleRate,

//   // Session Replay
//   replaysSessionSampleRate: sampleRate,
//   replaysOnErrorSampleRate: sampleRate,
// });

const sessionSampleRate = parseInt(Config?.REACT_APP_SENTRY_SAMPLE_RATE);
const errorSampleRate = parseInt(Config?.REACT_APP_SENTRY_ON_ERROR);

Sentry.init({
  dsn: Config?.REACT_APP_SENTRY_DSN,
  integrations: (integrations) => {
    let filtered = integrations.filter(function (integration) {
      return (
        integration.name !== 'TryCatch' && integration !== 'BrowserTracing'
      );
    });
    return [
      ...filtered,
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ];
  },
  release: sentryJSON.release,
  tracesSampleRate: errorSampleRate,
  replaysSessionSampleRate: sessionSampleRate,
  replaysOnErrorSampleRate: errorSampleRate,
});

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
